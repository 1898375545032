@import url(https://fonts.googleapis.com/css2?family=Texturina:ital,wght@0,500;0,700;0,900;1,500;1,900&display=swap);
.App {
  text-align: center;
  font-family: 'Texturina', serif;
}



body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

